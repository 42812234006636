import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'

export const query = graphql`
query {
    allEpisodesJson {
        nodes {
            title
            show
            fields {
                slug
            }
        }
    }
}
`

const HomePage = ({data}) => {

    return (
        <Layout>
            {data.allEpisodesJson.nodes.map(episode =>
                <p><Link to={`/${episode.show}/${episode.fields.slug}`}>{episode.title}</Link></p>
            )}
            <p><Link to="/test-404">4000000000000000004</Link></p>
            
            
        </Layout>
        )
    }
    
    
    export default HomePage